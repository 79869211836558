/*
| ==========================
| This File is for star icon
| ==========================
*/
<template>
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.26644 1.20204L5.26654 1.20221C5.26651 1.20216 5.26647 1.2021 5.26644 1.20204L5.33333 1.16178L5.26644 1.20204ZM5.33333 1.3132L6.74749 3.66304C6.82967 3.7996 6.96372 3.89699 7.11899 3.93295L9.79082 4.55176L7.99299 6.62284C7.88851 6.7432 7.83731 6.90078 7.85109 7.05957L8.08821 9.79185L5.56294 8.72201C5.41618 8.65983 5.25049 8.65983 5.10373 8.722L2.57845 9.79185L2.81558 7.05957C2.82936 6.90078 2.77816 6.7432 2.67368 6.62284L0.875845 4.55176L3.54767 3.93295C3.70295 3.89699 3.837 3.7996 3.91918 3.66304L5.33333 1.3132Z"
      :fill="fill"
      :stroke="stroke"
      stroke-width="0.666667"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconsStar',

  props: {
    fill: {
      type: String,
      default: '#FFFFFF'
    },

    stroke: {
      type: String,
      default: '#FFFCE7'
    }
  }
}
</script>
